#productsSection {
    scroll-margin-top: 140px;
}

.tituloProductos {
    text-align: center;
    font-size: var(--fs-titulos);
    color: #444;
    font-weight: 400;
    margin-top: 5rem;
    padding-bottom: 2%;
}

.ordenCardProductos {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    margin: 1% 0;
    padding: 0 5%;
    flex-wrap: wrap;
}

.cardProductos.container {
    width: 18vw;
    aspect-ratio: 1.1 / 1;
    margin: 0%;
    padding: 1rem;
}
.cardProductos.inner {
    width: 100%;
    height: 100%;
    padding: 0.5rem 1.5rem;
    display: flex;
    border-radius: 10px;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: transform 0.7s ease;
    background: transparent;
    box-sizing: border-box;
    /* remover lo de abajo si cambiamos las imagenes */
    /* opcional para ver si queda mejor con las fotos horrorosas esas */
    background: rgba(74, 81, 71, 0.17);
    background-position: center;
    background-size: 140%;
    /*  */
}

.cardProductos.container:hover > .cardProductos.inner {
    transform: rotateY(180deg);
}

.cardProductos:hover > .cardProductos.inner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: -1;
}

.textoCardProductos {
    color: white;
    font-size: 28px;
    width: 100%;
    text-align: end;
    position: relative;
    transform: rotateY(0deg);
    pointer-events: none;
    transition: 0.4s ease-in-out;
    font-weight: 300;
    line-height: 1.2;
    margin: 0.5em 0;
    align-self: flex-end;
    text-shadow: 1px 1px 3px #464646;
}

.nuevoTextoCardProductos {
    color: white;
    font-size: 16px;
    position: absolute;
    transform: rotateY(180deg);
    pointer-events: none;
    opacity: 0;
    transition: 0.2s ease-in-out;
    width: 90%;
    font-weight: 300;
    padding: 0 0.5rem;
    box-sizing: border-box;
    white-space: pre-wrap;
}

.cardProductos:hover .textoCardProductos {
    transition: 0.2s ease-in-out;
    opacity: 0;
}

.cardProductos:hover .nuevoTextoCardProductos {
    transition: 0.6s ease-in-out;
    opacity: 1;
}

/* Galaxy Fold  */

@media all and (max-width: 318px) {
    .tituloProductos {
        text-align: center;
        padding-top: 15%;
        padding-bottom: 7%;
    }

    .cardProductos.container {
        width: 90%;
        height: 200px;
        margin-bottom: 8%;
    }

    .textoCardProductos {
        font-size: 26px;
    }

    .nuevoTextoCardProductos {
        color: white;
        font-size: 14px;
        position: absolute;
        transform: rotateY(180deg);
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        width: 90%;
    }
}

/* dispositivos xxs */

@media all and (min-width: 318px) and (max-width: 374px) {
    .tituloProductos {
        text-align: center;
        padding-top: 15%;
        padding-bottom: 11%;
    }

    .cardProductos.container {
        width: 95%;
        height: 200px;
        margin-bottom: 2%;
    }

    .textoCardProductos {
        font-size: 26px;
    }

    .nuevoTextoCardProductos {
        color: white;
        font-size: 16px;
        position: absolute;
        transform: rotateY(180deg);
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        width: 90%;
    }
}

/* dispositivos xs */

@media all and (min-width: 374px) and (max-width: 390px) {
    .tituloProductos {
        text-align: center;
        padding-top: 15%;
        padding-bottom: 11%;
    }

    .cardProductos.container {
        width: 80%;
        height: 220px;
        margin-bottom: 2%;
    }

    .textoCardProductos {
        font-size: 26px;
    }

    .nuevoTextoCardProductos {
        color: white;
        font-size: 14px;
        position: absolute;
        transform: rotateY(180deg);
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        width: 90%;
    }
}

/* dispositivos small */

@media all and (min-width: 390px) and (max-width: 540px) {
    .tituloProductos {
        text-align: center;
        padding-top: 15%;
        padding-bottom: 11%;
    }

    .cardProductos.container {
        width: 80%;
        height: 220px;
        margin-bottom: 1%;
    }

    .textoCardProductos {
        font-size: 28px;
    }

    .nuevoTextoCardProductos {
        color: white;
        font-size: 14px;
        position: absolute;
        transform: rotateY(180deg);
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        width: 90%;
    }
}
/* dispositivos small */

@media all and (min-width: 540px) and (max-width: 768px) {
    .tituloProductos {
        text-align: center;
        padding-top: 15%;
        padding-bottom: 11%;
    }

    .cardProductos.container {
        width: 40%;
        height: 220px;
        margin-bottom: 1%;
    }

    .textoCardProductos {
        font-size: 28px;
    }

    .nuevoTextoCardProductos {
        color: white;
        font-size: 14px;
        position: absolute;
        transform: rotateY(180deg);
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        width: 90%;
    }
}

/* dispositivos medium */

@media all and (min-width: 768px) and (max-width: 992px) {
    .tituloProductos {
        padding-top: 6%;
        padding-bottom: 4%;
    }

    .cardProductos.container {
        width: 30%;
        height: 200px;
    }

    .textoCardProductos {
        font-size: 28px;
    }

    .nuevoTextoCardProductos {
        color: white;
        font-size: 15px;
        position: absolute;
        transform: rotateY(180deg);
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        width: 90%;
    }
}

/* dispositivos large */

@media all and (min-width: 992px) and (max-width: 1025px) {
    .tituloProductos {
        padding-top: 6%;
        padding-bottom: 4%;
    }

    .ordenCardProductos {
        margin: 1% 0;
        padding: 0 0%;
    }

    .cardProductos.container {
        width: 21%;
        height: 200px;
    }

    .textoCardProductos {
        font-size: 28px;
    }

    .nuevoTextoCardProductos {
        color: white;
        font-size: 16px;
        position: absolute;
        transform: rotateY(180deg);
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        width: 90%;
    }
}

/* dispositivos XL */

@media all and (min-width: 1025px) and (max-width: 1440px) {
    .tituloProductos {
        padding-top: 5%;
        padding-bottom: 3%;
    }

    .ordenCardProductos {
        margin: 1% 0;
        padding: 0 0%;
    }

    .cardProductos.container {
        width: 21%;
    }

    .textoCardProductos {
        font-size: 25px;
    }

    .nuevoTextoCardProductos {
        font-size: 16px;
    }
}
