.paginaPoliticas {
    display: flex;
}
.paginaPoliticas__infoSection {
    padding: 120px 5rem 7rem 7rem;
    background-color: var(--greenMain);
    color: #fff;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
}
.paginaPoliticas__img {
    width: 50%;
    object-fit: cover;
    filter: brightness(0.6);
}

.paginaPoliticas__title {
    font-size: 1.3rem;
    margin-bottom: 2rem;
}
.paginaPoliticas__text {
    font-size: 0.9rem;
}
.paginaPoliticas__btn {
    text-decoration: none;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    font-weight: 500;
    font-size: 1.1rem;
}
.paginaPoliticas__btn:hover {
    background-color: #fff;
    box-shadow: 2px 2px 15px #5c633e;
    color: #5c633e;
}

.paginaPoliticas__firma {
    margin: 0 1rem;
    text-align: center;
    font-weight: 200;
}
.paginaPoliticas__firma:nth-of-type(1) {
    margin: 0 1rem 0 auto;
}

.paginaPoliticas__dflex {
    display: flex;
    align-items: center;
    margin-top: 3rem;
}

/*Galaxy Fold */
@media all and (max-width: 318px) {
    .paginaPoliticas {
        flex-direction: column-reverse;
    }
    .paginaPoliticas__infoSection {
        padding: 3rem 1rem 7rem 1rem;
        width: 100%;
    }
    .paginaPoliticas__img {
        width: 100%;
        height: 300px;
    }
    .paginaPoliticas__dflex {
        flex-direction: column-reverse;
        align-items: center;
    }
    .paginaPoliticas__firma.paginaPoliticas__firma {
        margin: 0.5rem auto;
    }
    .paginaPoliticas__btn {
        margin-top: 3rem;
    }
}

/*dispositivos xxs */
@media all and (min-width: 318px) and (max-width: 374px) {
    .paginaPoliticas {
        flex-direction: column-reverse;
    }
    .paginaPoliticas__infoSection {
        padding: 3rem 2rem 7rem 2rem;
        width: 100%;
    }
    .paginaPoliticas__img {
        width: 100%;
        height: 300px;
    }
    .paginaPoliticas__dflex {
        flex-direction: column-reverse;
        align-items: center;
    }
    .paginaPoliticas__firma.paginaPoliticas__firma {
        margin: 0.5rem auto;
    }
    .paginaPoliticas__btn {
        margin-top: 3rem;
    }
}

/*dispositivos xs */
@media all and (min-width: 374px) and (max-width: 390px) {
    .paginaPoliticas {
        flex-direction: column-reverse;
    }
    .paginaPoliticas__infoSection {
        padding: 3rem 2rem 7rem 2rem;
        width: 100%;
    }
    .paginaPoliticas__img {
        width: 100%;
        height: 300px;
    }
    .paginaPoliticas__dflex {
        flex-direction: column-reverse;
        align-items: center;
    }
    .paginaPoliticas__firma.paginaPoliticas__firma {
        margin: 0.5rem auto;
    }
    .paginaPoliticas__btn {
        margin-top: 3rem;
    }
}

/*dispositivos small */
@media all and (min-width: 390px) and (max-width: 768px) {
    .paginaPoliticas {
        flex-direction: column-reverse;
    }
    .paginaPoliticas__infoSection {
        padding: 3rem 2rem 7rem 2rem;
        width: 100%;
    }
    .paginaPoliticas__img {
        width: 100%;
        height: 300px;
    }
    .paginaPoliticas__dflex {
        flex-direction: column-reverse;
        align-items: center;
    }
    .paginaPoliticas__firma.paginaPoliticas__firma {
        margin: 0.5rem auto;
    }
    .paginaPoliticas__btn {
        margin-top: 3rem;
    }
}

/*dispositivos medium */

@media all and (min-width: 768px) and (max-width: 1250px) {
    .paginaPoliticas {
        flex-direction: column-reverse;
    }
    .paginaPoliticas__infoSection {
        padding: 3rem 3rem 7rem 3rem;
        width: 100%;
    }
    .paginaPoliticas__img {
        width: 100%;
        height: 300px;
    }
}

/*dispositivos XL */
@media all and (min-width: 1250px) and (max-width: 1440px) {
    .paginaPoliticas {
        height: 100vh;
    }
    .paginaPoliticas__infoSection {
        padding: 120px 3rem 7rem 3rem;
        width: 45%;
    }
    .paginaPoliticas__img {
        width: 55%;
    }
}

/*dispositivos XXL */
@media all and (min-width: 1440px) {
    .paginaPoliticas {
        height: 100vh;
    }
}
