.mapaPaises {
    background-size: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.mapaPaises__bgImg {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(1.1);
    object-fit: cover;
    filter: blur(3px) brightness(.5);
}

.mapaPaises__title {
    font-size: var(--fs-titulos);
    margin-top: 5rem;
    font-weight: 300;
    text-align: center;
    color: #fff;
    position: relative;
    z-index: 1;
}

.mapaPaises__mapa {
    margin: 0 0 50px;
    position: relative;
    z-index: 1;
    width: 65%;
    margin: 0 auto 5rem;
}


/* Galaxy Fold */

@media all and (max-width: 318px) {
    .mapaPaises__mapa {
        width: 100%;
        transform: scale(1.1);
        margin: 2rem 0 5rem;
    }
}

/* dispositivos xxs */

@media all and (min-width: 318px) and (max-width: 374px) {
    .mapaPaises__mapa {
        width: 100%;
        transform: scale(1.1);
        margin: 2rem 0 5rem;
    }
}

/* dispositivos xs */

@media all and (min-width: 374px) and (max-width: 390px) {
    .mapaPaises__mapa {
        width: 100%;
        transform: scale(1.1);
        margin: 2rem 0 5rem;
    }
}

/* dispositivos small */

@media all and (min-width: 390px) and (max-width: 768px) {
    .mapaPaises__mapa {
        width: 95%;
    }
}

/* dispositivos medium */

@media all and (min-width: 768px) and (max-width: 992px) {
    .mapaPaises__mapa {
        width: 75%;
    }
}
