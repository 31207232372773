.mainEstadisticas {
    display: flex;
    justify-content: center;
}

.mainEstadisticas__card {
    text-align: center;
    background-color: #f7f7f7;
    box-shadow: 0px 3px 3px #bbbbbb;
    width: 13%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1.5rem;
}
.mainEstadisticas__cardNumber {
    font-size: 3.2rem;
    font-weight: 600;
    color: var(--greenMain);
    line-height: 1.2;
}
.mainEstadisticas__cardText {
    font-size: 1.2rem;
    color: #5f5f5f;
    font-weight: 500;
    line-height: 1.2;
    margin: 0%;
    white-space: pre-wrap;
}

/*Galaxy Fold */
@media all and (max-width: 318px) {
    .mainEstadisticas {
        margin: 4rem 0;
    }
    .mainEstadisticas {
        display: flex;
        flex-wrap: wrap;
    }
    .mainEstadisticas__card {
        width: 70%;
        margin: 0.5rem;
    }
    .mainEstadisticas__cardNumber {
        font-size: 2.6rem;
    }
    .mainEstadisticas__cardText {
        font-size: 1.2rem;
    }
}

/*dispositivos xxs */
@media all and (min-width: 318px) and (max-width: 374px) {
    .mainEstadisticas {
        margin: 4rem 0;
    }
    .mainEstadisticas {
        display: flex;
        flex-wrap: wrap;
    }
    .mainEstadisticas__card {
        width: 45%;
        margin: 0.5rem;
    }
    .mainEstadisticas__cardNumber {
        font-size: 2.4rem;
    }
    .mainEstadisticas__cardText {
        font-size: 1rem;
    }
}

/*dispositivos xs & small*/
@media all and (min-width: 374px) and (max-width: 540px) {
    .mainEstadisticas {
        margin: 5rem 0;
    }
    .mainEstadisticas {
        display: flex;
        flex-wrap: wrap;
    }
    .mainEstadisticas__card {
        width: 40%;
        margin: 1rem;
    }
    .mainEstadisticas__cardNumber {
        font-size: 2.8rem;
    }
    .mainEstadisticas__cardText {
        font-size: 1rem;
    }
}

/*dispositivos small */
@media all and (min-width: 540px) and (max-width: 768px) {
    .mainEstadisticas {
        margin: 5rem 0;
    }
    .mainEstadisticas {
        display: flex;
        flex-wrap: wrap;
    }
    .mainEstadisticas__card {
        width: 30%;
        margin: 1rem;
    }
    .mainEstadisticas__cardNumber {
        font-size: 2.8rem;
    }
    .mainEstadisticas__cardText {
        font-size: 1rem;
    }
}

/*dispositivos medium */
@media all and (min-width: 768px) and (max-width: 992px) {
    .mainEstadisticas {
        margin: 5rem 0;
    }
    .mainEstadisticas__card {
        width: 19%;
        margin: 0 1rem;
    }
    .mainEstadisticas__cardNumber {
        font-size: 2.8rem;
    }
    .mainEstadisticas__cardText {
        font-size: 1rem;
    }
}

/*dispositivos large */
@media all and (min-width: 992px) and (max-width: 1025px) {
    .mainEstadisticas {
        margin: 6rem 0;
    }
    .mainEstadisticas__card {
        width: 18%;
        margin: 0 1.3rem;
    }
    .mainEstadisticas__cardNumber {
        font-size: 3rem;
    }
    .mainEstadisticas__cardText {
        font-size: 1rem;
    }
}

/*dispositivos XL */
@media all and (min-width: 1025px) and (max-width: 1440px) {
    .mainEstadisticas {
        margin: 6rem 0;
    }
    .mainEstadisticas__card {
        width: 17%;
        margin: 0 1.3rem;
    }
    .mainEstadisticas__cardNumber {
        font-size: 3.2rem;
    }
    .mainEstadisticas__cardText {
        font-size: 1.2rem;
    }
}

/*dispositivos XXL */
@media all and (min-width: 1440px) and (max-width: 2560px) {
    .mainEstadisticas {
        margin: 6rem 0;
    }
    .mainEstadisticas__card {
        width: 13%;
        margin: 0 1.5rem;
    }
    .mainEstadisticas__cardNumber {
        font-size: 3.2rem;
    }
    .mainEstadisticas__cardText {
        font-size: 1.2rem;
    }
}

/*dispositivos 4k */
@media all and (min-width: 2560px) and (max-width: 3000px) {
    .mainEstadisticas__card {
        width: 13%;
        margin: 0 1.5rem;
    }
    .mainEstadisticas__cardNumber {
        font-size: 5rem;
    }
    .mainEstadisticas__cardText {
        font-size: 1.2rem;
    }
}
