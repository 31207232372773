.mainFooter {
    background-color: #434343;
    color: #fff;
    padding: 5rem 7rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.mainFooter__header {
    display: block;
    width: 100%;
    text-align: center;
    order: 1;
}
.mainFooter__logo {
    width: 10rem;
    filter: contrast(0) brightness(1000%);
}
.mainFooter__header h4 {
    font-weight: 400;
    font-size: 1rem;
}

.mainFooter__column {
    margin-top: 3rem;
    width: 25%;
    min-width: 300px;
}
.mainFooter__column:nth-last-of-type(2),
.mainFooter__column:nth-last-of-type(4) {
    flex-shrink: 1;
}
.mainFooter__column h3 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
}
.mainFooter__column a {
    color: #f0f0f0;
    text-decoration: none;
    font-weight: 200;
    margin: 0.7rem 0;
    font-size: 0.9rem;
}
.mainFooter__column a:hover {
    font-weight: 400;
}

.mainFooter__contact {
    display: flex;
    flex-direction: column;
    order: 2;
}
.mainFooter__contact img {
    height: 1.5rem;
    aspect-ratio: 1 / 1;
    margin-right: 1rem;
    filter: contrast(0.77);
}

.mainFooter__institutions {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    order: 3;
}
.institutions__container img {
    height: 2.5rem;
    margin: 1rem 0.3rem 3rem;
}
.certificates__container img {
    height:5rem;
    margin: 1rem 0.5rem;
}

.mainFooter__links {
    display: flex;
    flex-direction: column;
    text-align: end;
    order: 4;
}

.mainFooterauthor {
    text-align: center;
    display: block;
    width: 100%;
    border-top: 1px solid #aaa;
    margin-top: 5rem;
    padding: 1.5rem 0;
    flex-direction: row;
    order: 5;
}
.mainFooterauthor h4 {
    font-weight: 300;
    display: inline-block;
    font-size: 0.9rem;
}
.mainFooterauthor img {
    margin: 0 0.5rem;
    height: 1.1rem;
}
.mainFooterauthor img.radix {
    margin: 0 0.5rem -0.3rem;
}
.mainFooterauthor img.mindStudio {
    margin: 0 0.5rem -0.1rem;
}

/* Galaxy Fold */

@media all and (max-width: 319px) {
    .mainFooter {
        flex-direction: column;
        align-items: center;
        padding: 5rem 1rem;
    }
    .mainFooter__column h3 {
        text-align: center;
    }

    .mainFooter__column {
        min-width: 240px;
    }
    .mainFooter__contact {
        order: 3;
    }
    .mainFooter__institutions {
        width: 100%;
        order: 2;
    }
    .mainFooter__links {
        text-align: center;
    }
}

/* dispositivos xxs */

@media all and (min-width: 319px) and (max-width: 374px) {
    .mainFooter {
        flex-direction: column;
        align-items: center;
        padding: 5rem 1rem;
    }
    .mainFooter__column h3 {
        text-align: center;
    }

    .mainFooter__contact {
        order: 3;
    }
    .mainFooter__institutions {
        width: 100%;
        order: 2;
    }
    .mainFooter__links {
        text-align: center;
    }
}

/* dispositivos xs */

@media all and (min-width: 374px) and (max-width: 390px) {
    .mainFooter {
        flex-direction: column;
        align-items: center;
        padding: 5rem 1rem;
    }
    .mainFooter__column h3 {
        text-align: center;
    }

    .mainFooter__contact {
        order: 3;
    }
    .mainFooter__institutions {
        width: 100%;
        order: 2;
    }
    .mainFooter__links {
        text-align: center;
    }
}

/* dispositivos small */

@media all and (min-width: 390px) and (max-width: 768px) {
    .mainFooter {
        flex-direction: column;
        align-items: center;
        padding: 5rem 1rem;
    }
    .mainFooter__column h3 {
        text-align: center;
    }

    .mainFooter__contact {
        order: 3;
    }
    .mainFooter__institutions {
        width: 100%;
        order: 2;
    }
    .mainFooter__links {
        text-align: center;
    }
}

/* dispositivos medium */

@media all and (min-width: 768px) and (max-width: 992px) {
    .mainFooter {
        flex-direction: column;
        align-items: center;
    }
    .mainFooter__column h3 {
        text-align: center;
    }

    .mainFooter__contact {
        order: 3;
    }
    .mainFooter__institutions {
        width: 100%;
        order: 2;
    }
    .mainFooter__links {
        text-align: center;
    }
}

/* dispositivos large */

@media all and (min-width: 992px) and (max-width: 1025px) {
    .mainFooter {
        flex-direction: column;
        align-items: center;
    }
    .mainFooter__column h3 {
        text-align: center;
    }

    .mainFooter__contact {
        order: 3;
    }
    .mainFooter__institutions {
        width: 100%;
        order: 2;
    }
    .mainFooter__links {
        text-align: center;
    }
}

/* dispositivos XL */

@media all and (min-width: 1025px) and (max-width: 1440px) {
    .mainFooter__column h3 {
        text-align: center;
    }

    .mainFooter__contact {
        order: 3;
    }
    .mainFooter__institutions {
        width: 100%;
        order: 2;
    }
}

/* dispositivos XXL */

@media all and (min-width: 1440px) and (max-width: 2560px) {
}

/* dispositivos 4k */

@media all and (min-width: 2560px) and (max-width: 3000px) {
}
