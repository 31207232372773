#errorTitle {
    font-size: 3.5rem;
}

#errorParagraph {
    font-size: 2rem;
}

#errorBtn {
    text-align: center;
    padding: 1rem 0.5rem;
    margin-top: 3rem;
}

@media screen and (max-width: 768px) {
    .paginaError {
        text-align: center;
    }
}
