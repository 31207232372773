.generalRedireccion {
    background-color: #f5f5f5;
    padding-top: 2%;
    padding-bottom: 2%;
}

.generalRedireccionTitle {
    font-size: var(--fs-titulos);
    text-align: center;
    font-family: Montserrat;
    font-weight: 300;
    color: #333;
    margin-top: 2.5rem;
    margin-bottom: 3.5rem;
}

.ordenCardsRedireccion {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 2% 0%;
}

.imagenCardsRedireccion {
    width: 100%;
}

.cardsRedireccion {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 18%;
    aspect-ratio: 1 / 1;
    margin: 0 1rem;
    border-radius: 5%;
    border-radius: 15px;
    background: #fafafa;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.textoCardsRedireccion {
    text-decoration: none;
    border-radius: 15px;
    background: #fafafa;
    padding: 2% 5%;
    border-radius: 10px;
    color: #8f8f8f;
    border: solid 1px #8f8f8f;
    font-size: 16px;
    max-width: 88%;
    margin: auto 0;
}

.textoCardsRedireccion:hover {
    background-color: #8f8f8f;
    border: solid 1px #8f8f8f;
    color: white;
}

/* Galaxy Fold  */

@media all and (max-width: 318px) {
    .generalRedireccion {
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .ordenCardsRedireccion {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .imagenCardsRedireccion {
        width: 100%;
    }

    .cardsRedireccion {
        width: 85%;
        margin-bottom: 12%;
    }

    .textoCardsRedireccion {
        padding: 7% 8%;
        font-size: 14px;
        max-width: 85%;
    }
}

/* dispositivos xxs */

@media all and (min-width: 319px) and (max-width: 373px) {
    .generalRedireccion {
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .ordenCardsRedireccion {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .imagenCardsRedireccion {
        width: 100%;
    }

    .cardsRedireccion {
        width: 85%;
        margin-bottom: 12%;
    }

    .textoCardsRedireccion {
        padding: 5% 7%;
        font-size: 15px;
        max-width: 85%;
    }
}

/* dispositivos xs */

@media all and (min-width: 374px) and (max-width: 389px) {
    .generalRedireccion {
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .ordenCardsRedireccion {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .imagenCardsRedireccion {
        width: 100%;
    }

    .cardsRedireccion {
        width: 85%;
        margin-bottom: 12%;
    }

    .textoCardsRedireccion {
        padding: 5% 7%;
        font-size: 15px;
        max-width: 85%;
    }
}

/* dispositivos small */

@media all and (min-width: 390px) and (max-width: 540px) {
    .generalRedireccion {
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .ordenCardsRedireccion {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .imagenCardsRedireccion {
        width: 100%;
    }

    .cardsRedireccion {
        width: 80%;
        aspect-ratio: 1 / 1;
        margin-bottom: 5%;
    }

    .textoCardsRedireccion {
        padding: 2% 5%;
        font-size: 17px;
        max-width: 85%;
    }
}

/* dispositivos medium */

@media all and (min-width: 540px) and (max-width: 768px) {
    .ordenCardsRedireccion {
        flex-wrap: wrap;
        margin: 2% 1rem;
    }

    .cardsRedireccion {
        width: 40%;
        margin: 1rem;
        margin-bottom: 5%;
    }

    .textoCardsRedireccion {
        padding: 2% 5%;
        font-size: 15px;
        max-width: 95%;
    }
}
/* dispositivos medium */

@media all and (min-width: 768px) and (max-width: 992px) {
    .ordenCardsRedireccion {
        flex-wrap: wrap;
        margin: 2% 3rem;
    }

    .cardsRedireccion {
        width: 28vw;
        margin: 1rem;
        margin-bottom: 5%;
    }

    .textoCardsRedireccion {
        padding: 2% 5%;
        font-size: 15px;
        max-width: 95%;
    }
}

/* dispositivos large */

@media all and (min-width: 992px) and (max-width: 1025px) {
    .generalRedireccion {
        background-color: #f5f5f5;
        padding-top: 2%;
        padding-bottom: 2%;
    }

    .ordenCardsRedireccion {
        margin: 2% 2%;
    }

    .cardsRedireccion {
        width: 23%;
    }

    .textoCardsRedireccion {
        padding: 2% 5%;
        border-radius: 10px;
        color: #8f8f8f;
        border: solid 1px #8f8f8f;
        font-size: 13px;
        max-width: 94%;
    }
}

/* dispositivos XL */

@media all and (min-width: 1025px) and (max-width: 1440px) {
    .cardsRedireccion {
        width: 21%;
        aspect-ratio: 1 / 1;
    }
}

/* dispositivos XXL */

@media all and (min-width: 1440px) and (max-width: 2559px) {
    .textoCardsRedireccion {
        padding: 2% 5%;
        border-radius: 10px;
        color: #8f8f8f;
        border: solid 1px #8f8f8f;
        font-size: 14.5px;
        max-width: 96%;
    }

    .textoCardsRedireccion:hover {
        background-color: #8f8f8f;
        border: solid 1px #8f8f8f;
        color: white;
    }
}

/* dispositivos 4k */

@media all and (min-width: 2560px) and (max-width: 3000px) {
    .textoCardsRedireccion {
        font-size: 24px;
    }
}
