@font-face {
  font-family: "Montserrat";
  src: url(../assets/font/Montserrat-Thin.ttf);
  font-weight: 100;
}
@font-face {
  font-family: "Montserrat";
  src: url(../assets/font/Montserrat-ExtraLight.ttf);
  font-weight: 200;
}
@font-face {
  font-family: "Montserrat";
  src: url(../assets/font/Montserrat-Light.ttf);
  font-weight: 300;
}
@font-face {
  font-family: "Montserrat";
  src: url(../assets/font/Montserrat-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat";
  src: url(../assets/font/Montserrat-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: "Montserrat";
  src: url(../assets/font/Montserrat-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: "Montserrat";
  src: url(../assets/font/Montserrat-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "Montserrat";
  src: url(../assets/font/Montserrat-ExtraBold.ttf);
  font-weight: 800;
}
@font-face {
  font-family: "Montserrat";
  src: url(../assets/font/Montserrat-Black.ttf);
  font-weight: 900;
}

:root {
  --fs-titulos: 3.33rem;
  --greenMain: #777d5d;
  --greySlider: #ebebeb;
  --lightGrey: #f5f5f5;
  --colorFooter: #434343;
}

html {
  font-size: 100%;
}

body {
  margin: 0%;
  font-family: "Montserrat";
}

.paginaInicio {
  overflow: hidden;
}

/* Galaxy Fold */
@media all and (max-width: 318px) {
  :root {
    --fs-titulos: 1.8rem;
  }
}
/* dispositivos xxs */
@media all and (min-width: 318px) and (max-width: 374px) {
  :root {
    --fs-titulos: 1.8rem;
  }
}
/* dispositivos xs */
@media all and (min-width: 374px) and (max-width: 390px) {
  :root {
    --fs-titulos: 1.8rem;
  }
}
/* dispositivos small */
@media all and (min-width: 390px) and (max-width: 768px) {
  :root {
    --fs-titulos: 1.4rem;
  }
}
/* dispositivos medium */
@media all and (min-width: 768px) and (max-width: 992px) {
  :root {
    --fs-titulos: 2rem;
  }
}
/* dispositivos large */
@media all and (min-width: 992px) and (max-width: 1026px) {
  :root {
    --fs-titulos: 1.5rem;
  }
}
/* dispositivos XL */
@media all and (min-width: 1025px) and (max-width: 1440px) {
  :root {
    --fs-titulos: 2rem;
  }
}
/* dispositivos XXL */
@media all and (min-width: 1440px) and (max-width: 2560px) {
  :root {
    --fs-titulos: 2rem;
  }
}
/* dispositivos 4k */
@media all and (min-width: 2560px) {
  :root {
    --fs-titulos: 4rem;
  }
}
