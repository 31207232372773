.ordenGeneralInstituciones {
  display: flex;
  flex-direction: column;
  margin: 4% 28%;
  gap: 45px;
}

.tituloInsituciones {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}

.ordenImagenesInstituciones {
  display: flex;
  justify-content: space-around;
  margin-top: 4%;
}

.ordenLogosInstituciones {
  display: flex;
  justify-content: center;
  gap: 3%;
  margin-top: 4%;
}



/* Galaxy Fold  */

@media all and (max-width: 390px) {
  .ordenGeneralInstituciones {
    display: flex;
    flex-direction: column;
    margin: 8% 5%;
    gap: 30px;
  }

  .tituloInsituciones {
    text-align: center;
    font-size: 14px;
  }

  .ordenImagenesInstituciones {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 6%;
  }

  .ordenLogosInstituciones {
    display: flex;
    justify-content: center;
    gap: 3%;
    margin-top: 4%;
  }

  .imagenInstituciones {
    width: 30%;
  }

  .imagenLogos {
    width: 15%;
  }

  .imagenTuc {
    width: 20%;
  }
}

/* dispositivos small */

@media all and (min-width: 390px) and (max-width: 768px) {
  .ordenGeneralInstituciones {
    display: flex;
    flex-direction: column;
    margin: 8% 5%;
    gap: 30px;
  }

  .tituloInsituciones {
    text-align: center;
    font-size: 16px;
  }

  .ordenImagenesInstituciones {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 6%;
  }

  .ordenLogosInstituciones {
    display: flex;
    justify-content: center;
    gap: 3%;
    margin-top: 4%;
  }

  .imagenInstituciones {
    width: 30%;
  }

  .imagenLogos {
    width: 15%;
  }

  .imagenTuc {
    width: 20%;
  }
}

/* dispositivos medium */

@media all and (min-width: 768px) and (max-width: 992px) {
  .ordenGeneralInstituciones {
    display: flex;
    flex-direction: column;
    margin: 4% 25%;
    gap: 45px;
  }

  .tituloInsituciones {
    text-align: center;
    font-size: 22px;
  }

  .ordenImagenesInstituciones {
    display: flex;
    justify-content: space-around;
    margin-top: 4%;
    gap: 15px;
  }

  .ordenLogosInstituciones {
    display: flex;
    justify-content: center;
    gap: 3%;
    margin-top: 4%;
  }

  .imagenLogos {
    width: 20%;
  }

  .imagenTuc {
    width: 30%;
  }
}

/* dispositivos large */

@media all and (min-width: 992px) and (max-width: 1025px) {
  .ordenGeneralInstituciones {
    display: flex;
    flex-direction: column;
    margin: 4% 25%;
    gap: 45px;
  }

  .tituloInsituciones {
    text-align: center;
    font-size: 22px;
  }

  .ordenImagenesInstituciones {
    display: flex;
    justify-content: space-around;
    margin-top: 4%;
    gap: 15px;
  }

  .ordenLogosInstituciones {
    display: flex;
    justify-content: center;
    gap: 3%;
    margin-top: 4%;
  }

  .imagenLogos {
    width: 20%;
  }

  .imagenTuc {
    width: 30%;
  }
}

@media all and (min-width: 1025px) and (max-width: 1440px) {
  .imagenLogos, .imagenInstituciones, .imagenTuc {
    height: 5.5rem;
  }
}
@media all and (min-width: 1440px) and (max-width: 2560px) {
  .imagenLogos, .imagenInstituciones, .imagenTuc {
    height: 5.5rem;
  }
}

/* dispositivos 4k */

@media all and (min-width: 2560px) and (max-width: 3000px) {
  .tituloInsituciones {
    font-size: 32px;
  }
}
