.ordenGeneralCardMision {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 3% 7%;
    box-sizing: border-box;
    font-family: "Montserrat";
}

.cardMision:nth-of-type(1) {
    background-image: url(../assets/trayectoria.jpg);
}
.cardMision:nth-of-type(2) {
    background-image: url(../assets/mision.jpg);
}
.cardMision:nth-of-type(3) {
    background-image: url(../assets/vision.jpg);
}

.cardMisionBgFilter {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #535353b4;
    z-index: 2;
    border-radius: 15px;
}

.cardMision {
    position: relative;
    z-index: 1;
    width: 26%;
    height: 520px;
    border-radius: 15px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 3%;
    margin-bottom: 1.5rem;
    background-size: cover;
    background-position: center;
    text-align: center;
}

.tituloCardMision {
    color: white;
    font-weight: 400;
    font-size: 25px;
    z-index: 3;
}

.textoCardMision {
    margin-top: 1rem;
    color: white;
    font-size: 18px;
    font-weight: 400;
    z-index: 3;
    white-space: pre-wrap;
}
.textoCardMision.active {
    font-size: 0.9em;
    overflow-y: scroll;
}

.botonCardMision {
    background-color: transparent;
    font-family: Montserrat;
    color: white;
    border-radius: 15px;
    border: 2px solid #fff;
    padding: 2% 15%;
    font-size: 18px;
    z-index: 3;
}

.botonCardMision:hover {
    background-color: white;
    color: black;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f134;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* dispositivos small  */

@media all and (max-width: 767px) {
    .ordenGeneralCardMision {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 15% 2%;
    }

    .cardMision {
        width: 80%;
        height: 400px;
        padding: 7%;
        margin-bottom: 1rem;
    }

    .tituloCardMision {
        font-size: 25px;
    }

    .textoCardMision {
        font-size: 15px;
    }

    .botonCardMision {
        font-size: 18px;
        padding: 0.5rem 1rem;
        width: 80%;
        border: 1px solid #fff;
    }
}

/* dispositivos medium */

@media all and (min-width: 768px) and (max-width: 991px) {
    .ordenGeneralCardMision {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 5% 2%;
    }

    .cardMision {
        width: 40%;
        height: 420px;
    }

    .tituloCardMision {
        font-size: 27px;
    }

    .textoCardMision {
        font-size: 17px;
    }

    .botonCardMision {
        font-size: 15px;
    }
}

/* dispositivos large */

@media all and (min-width: 992px) and (max-width: 1025px) {
    .ordenGeneralCardMision {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 5% 2%;
    }

    .cardMision {
        width: 40%;
        height: 420px;
    }

    .tituloCardMision {
        font-size: 27px;
    }

    .textoCardMision {
        font-size: 13px;
    }

    .botonCardMision {
        font-size: 15px;
    }
}
