.barraVerdeContacto {
    background-color: #777d5d;
    padding-top: 75px;
}

.seccionCardsContacto {
    margin: 2% 8%;
    display: flex;
    justify-content: center;
}

.seccionCardsContacto .card {
    width: 50%;
    margin: 5rem;
    border: none;
}

.tituloContacto {
    font-size: var(--fs-titulos);
    font-weight: 400;
    color: white;
    text-align: center;
    padding: 1.5% 0%;
}

.textoContacto {
    background-color: #79797912;
    padding: 5%;
}

.iconoContacto {
    font-size: 25px;
    padding-right: 3%;
}

.ordenParrafoContacto {
    display: flex;
    align-items: center;
    font-size: 20px;
    text-decoration: none;
    margin-bottom: 0.7rem;
    font-weight: 300;
    color: #5e5e5e;
}
.ordenParrafoContacto:hover {
    font-weight: 400;
}
.ordenParrafoContacto i {
    color: #000;
    margin-right: 1rem;
}
.tituloFormContacto {
    font-size: 1.2rem;
    font-weight: 500;
}

.textoFormContacto {
    margin: 0%;
    margin-top: 5%;
    font-size: 17px;
}

.inputContacto {
    width: 100%;
    padding: 0.8rem;
    border-radius: 5px;
    border: solid 1px #777d5d;
    resize: none;
    max-height: 100px;
    font-family: Montserrat;
    font-size: 1rem;
    box-sizing: border-box;
}

.botonFormContacto {
    background-color: #777d5d;
    border: solid 2px #777d5d;
    color: white;
    padding: 0.5rem;
    width: 45%;
    max-width: 200px;
    margin-top: 2%;
    border-radius: 5px;
    font-size: 1rem;
    font-family: Montserrat;
    cursor: pointer;
}

.botonFormContacto:hover {
    background-color: white;
    color: #777d5d;
    border: solid 2px #777d5d;
}

.ordenBotonContacto {
    display: flex;
    justify-content: end;
}

.error {
    color: red;
    font-weight: 600;
}

/*Galaxy Fold */

@media all and (max-width: 318px) {
    .seccionCardsContacto {
        margin: 3rem 1.5rem;
        flex-direction: column-reverse;
    }
    .seccionCardsContacto .card {
        flex-grow: 1;
        width: auto;
        margin: 0 0 3rem;
    }
    .tituloFormContacto {
        font-size: 1.2rem;
        text-align: center;
        font-weight: 400;
    }
    .ordenParrafoContacto {
        font-size: 1.1rem;
    }
    .botonFormContacto {
        width: 100%;
        max-width: initial;
    }
}

/*dispositivos xxs */

@media all and (min-width: 318px) and (max-width: 374px) {
    .seccionCardsContacto {
        margin: 3rem 1.5rem;
        flex-direction: column-reverse;
    }
    .seccionCardsContacto .card {
        flex-grow: 1;
        width: auto;
        margin: 0 0 3rem;
    }
    .tituloFormContacto {
        font-size: 1.2rem;
        text-align: center;
        font-weight: 400;
    }
    .ordenParrafoContacto {
        font-size: 1.1rem;
    }
    .botonFormContacto {
        width: 100%;
        max-width: initial;
    }
}

/*dispositivos xs */

@media all and (min-width: 374px) and (max-width: 390px) {
    .seccionCardsContacto {
        margin: 3rem 1.5rem;
        flex-direction: column-reverse;
    }
    .seccionCardsContacto .card {
        flex-grow: 1;
        width: auto;
        margin: 0 0 3rem;
    }
    .botonFormContacto {
        width: 100%;
        max-width: initial;
    }
}

/*dispositivos small */

@media all and (min-width: 390px) and (max-width: 540px) {
    .seccionCardsContacto {
        margin: 3rem 2rem;
        flex-direction: column-reverse;
    }
    .seccionCardsContacto .card {
        flex-grow: 1;
        width: auto;
        margin: 0 0 3rem;
    }
    .botonFormContacto {
        width: 100%;
        max-width: initial;
    }
}
/*dispositivos small */

@media all and (min-width: 540px) and (max-width: 770px) {
    .seccionCardsContacto {
        margin: 5rem 3rem;
        flex-direction: column-reverse;
    }
    .seccionCardsContacto .card {
        flex-grow: 1;
        width: auto;
        margin: 0 0 5rem;
    }
    .botonFormContacto {
        width: 100%;
        max-width: initial;
    }
}

/*dispositivos medium */

@media all and (min-width: 770px) and (max-width: 992px) {
    .seccionCardsContacto {
        margin: 5rem 1rem;
    }
    .seccionCardsContacto .card {
        width: 50%;
        margin: 1rem;
    }
}

/*dispositivos large */

@media all and (min-width: 992px) and (max-width: 1025px) {
    .seccionCardsContacto {
        margin: 2% 3%;
    }
    .seccionCardsContacto .card {
        width: 50%;
        margin: 3rem;
    }
}

/*dispositivos XL */

@media all and (min-width: 1025px) and (max-width: 1440px) {
    .seccionCardsContacto {
        margin: 2% 3%;
    }
    .seccionCardsContacto .card {
        width: 50%;
        margin: 3rem;
    }
}

/*dispositivos XXL */

@media all and (min-width: 1440px) and (max-width: 2560px) {
}

/*dispositivos 4k */

@media all and (min-width: 2560px) and (max-width: 3000px) {
}
