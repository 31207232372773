:root {
    --texto-subt: 1.5rem;
}

.home-banner {
    height: 100vh;
    overflow: hidden;
}
.banner-innerContainer {
    height: 100%;
    position: relative;
}
.individualPic {
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
}

.picture-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    background: rgba(1, 1, 1, 0.673);
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.home-bannerTitle {
    font-size: var(--fs-titulos);
    font-weight: 400;
    margin: auto auto 0.5em;
}
.home-bannerSubtitle {
    font-weight: 300;
    margin: 0 auto auto;
    font-size: var(--texto-subt);
}
.home-bannerPhrase {
    font-weight: 300;
    margin: 0 auto 2rem;
    font-size: var(--texto-subt);
}

/* individual images */

.banner-pic1 {
    animation: bannerImgAnimation 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    will-change: opacity;
    object-position: top;
}
.banner-pic2 {
    animation: bannerImgAnimation 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    will-change: opacity;
    animation-delay: 10s;
    opacity: 0;
}
.banner-pic3 {
    animation: bannerImgAnimation 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    will-change: opacity;
    animation-delay: 20s;
    opacity: 0;
}

@keyframes bannerImgAnimation {
    0% {
        opacity: 0;
        transform: scale(1.2);
    }
    1% {
        opacity: 1;
    }
    33% {
        opacity: 1;
    }
    34% {
        opacity: 0;
        transform: scale(1);
    }
    100% {
        opacity: 0;
    }
}

/* // X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap */

/* // ExtraSmall devices (Galaxy Fold phone, Less than 380px) */
@media screen and (max-width: 380px) {
    .banner-innerContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    :root {
        --texto-subt: 1.1rem;
    }
    .home-bannerTitle {
        width: 90%;
    }
    .home-bannerSubtitle {
        width: 90%;
    }
    .home-bannerPhrase {
        width: 90%;
    }
}
/* // Small devices ( phones, 380px and up) */
@media screen and (min-width: 380px) and (max-width: 576px) {
    .banner-innerContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    :root {
        --texto-subt: 1.1rem;
    }
    .home-bannerTitle {
        width: 90%;
    }
    .home-bannerSubtitle {
        width: 90%;
    }
    .home-bannerPhrase {
        width: 90%;
    }
}
/* // Small devices (landscape phones, 576px and up) */
@media screen and (min-width: 576px) and (max-width: 768px) {
    .banner-innerContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    :root {
        --texto-subt: 1.2rem;
    }
    .home-bannerTitle {
        width: 80%;
    }
    .home-bannerSubtitle {
        width: 75%;
    }
    .home-bannerPhrase {
        width: 75%;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 992px) {
    :root {
        --texto-subt: 1.2rem;
    }
    .home-bannerTitle {
        width: 60%;
    }
    .home-bannerSubtitle {
        width: 60%;
    }
    .home-bannerPhrase {
        width: 40%;
    }
}

/* // Large devices (desktops, 992px and up) */
@media screen and (min-width: 992px) and (max-width: 1200px) {
    :root {
        --texto-subt: 0.9rem;
    }
    .home-bannerTitle {
        width: 60%;
    }
    .home-bannerSubtitle {
        width: 50%;
    }
    .home-bannerPhrase {
        width: 40%;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media screen and (min-width: 1200px) and (max-width: 1400px) {
    :root {
        --texto-subt: 1.2rem;
    }
    .home-bannerTitle {
        width: 60%;
    }
    .home-bannerSubtitle {
        width: 50%;
    }
    .home-bannerPhrase {
        width: 40%;
    }
}
@media screen and (min-width: 1440px) and (max-width: 2560px) {
    :root {
        --texto-subt: 1.3rem;
    }
    .home-bannerTitle {
        width: 60%;
    }
    .home-bannerSubtitle {
        width: 50%;
    }
    .home-bannerPhrase {
        width: 40%;
    }
}

/* dispositivos 4k */

@media screen and (min-width: 2560px) {
    :root {
        --texto-subt: 2.5rem;
    }
    .home-bannerTitle {
        width: 60%;
    }
    .home-bannerSubtitle {
        width: 50%;
    }
    .home-bannerPhrase {
        width: 40%;
    }
}
