.mainHeader {
    color: #fff;
    align-items: center;
    font-weight: 300;
    height: 75px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    position: fixed;
    z-index: 100;
    padding: 0 1.5rem;
    transition: 0.2s;
}
.mainHeader.white {
    color: #000;
    background-color: #fff;
    box-shadow: 2px 0 9px #aaa;
    transition: 0.2s;
}
.mainHeader__logo {
    height: 100%;
    margin-right: auto;
    filter: contrast(0) brightness(1000%);
}
.mainHeader.white .mainHeader__logo {
    filter: none;
}
.mainHeader__img {
    height: 100%;
    width: 100px;
}
.mainHeader__nav {
    display: flex;
}
.mainHeader__link {
    margin: 0 0.4rem;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.3rem;
}
.mainHeader__link:hover {
    font-weight: 400;
}
.mainHeader__lang {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
}
.mainHeader__lang img {
    background-color: transparent;
    object-fit: cover;
    border: none;
    color: #fff;
    width: 30px;
    aspect-ratio: 16 / 12;
}
.mainHeader__hamburguer {
    width: 45px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: none;
    display: none;
}
.mainHeader__hamburguer .bar {
    border-radius: 2px;
    margin: 4px;
    display: block;
    height: 3px;
    width: 100%;
    background-color: #fff;
    transition: 0.2s;
}

.mainHeader.white .bar {
    background-color: #555;
}

.mainHeader__hamburguer .bar:nth-of-type(1).active {
    transform: rotate(45deg) translateY(0.5rem) translateX(0.5rem);
}
.mainHeader__hamburguer .bar:nth-of-type(2).active {
    opacity: 0;
}
.mainHeader__hamburguer .bar:nth-of-type(3).active {
    transform: rotate(-45deg) translateY(-0.5rem) translateX(0.5rem);
}

/* Hamburguer button for mobile and tablets */
@media screen and (max-width: 992px) {
    .mainHeader.active {
        background-color: rgba(0, 0, 0, 0.88);
        transition: 0.2s;
    }
    .mainHeader.white {
        color: #fff;
        background-color: #fff;
        transition: 0.2s;
    }

    .mainHeader__hamburguer {
        display: flex;
    }
    .mainHeader__nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 85vh;
        justify-content: space-evenly;
        position: absolute;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.88);
        top: 75px;
        left: -100%;
        transition: 0.2s;
    }
    .mainHeader__nav.active {
        top: 75px;
        left: 0px;
    }

    .mainHeader__link {
        color: #fff;
        font-weight: 200;
        margin: 2rem 0;
        text-decoration: none;
        font-size: 2rem;
    }
    .mainHeader.white .mainHeader__link {
        font-weight: 300;
    }
}

@media screen and (min-width: 992px) and (max-width: 2560px) {
    .mainHeader__link {
        color: #fff;
    }
    .mainHeader.white .mainHeader__link {
        color: #444;
    }
    .mainHeader.white .mainHeader__link {
        font-weight: 400;
        color: #444;
    }
}

@media screen and (min-width: 2560px) {
    .mainHeader__link {
        font-size: 2.3rem;
        color: #fff;
    }
    .mainHeader.white .mainHeader__link {
        color: #444;
    }
    .mainHeader.white .mainHeader__link {
        font-weight: 400;
        color: #444;
    }
}
