.ordenGeneralFerias.ordenGeneralFerias {
    position: relative;
    margin: 10rem 0;
    padding: 4rem 0;
    background-color: transparent;
}
.tituloFerias.tituloFerias {
    font-size: var(--fs-titulos);
    text-align: center;
    font-weight: 300;
    color: #5e5e5e;
    padding-bottom: 3rem;
}
.carrouselItem__title {
    position: absolute;
    text-align: start;
    font-weight: 300;
    width: 100%;
    bottom: 0%;
    color: white;
    padding: 0.5rem;
    font-size: 2rem;
    margin: 0;
}

.carrouselItem__container {
    position: relative;
    cursor: pointer;
}

.carrouselItem__img {
    aspect-ratio: 1 / 1;
    object-fit: cover;
}
.carrouselItem__img.shadow {
    filter: brightness(0.5);
}

.carrouselItem__button {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 50;
    left: 0px;
    background-color: transparent;
    border: none;
    color: transparent;
    cursor: pointer;
}

/* Background grey lines */
.carrouselBg {
    position: absolute;
    width: 150%;
    height: 110%;
    background-color: var(--greySlider);
    z-index: -1;
    top: 0;
    left: -30%;
}
.carrouselBg:nth-of-type(1) {
    transform: rotate(3deg);
    top: -1.5rem;
}
.carrouselBg:nth-of-type(2) {
    transform: rotate(-5deg);
    top: 1rem;
}

/*Galaxy Fold */
@media all and (max-width: 318px) {
    .ordenGeneralFerias.ordenGeneralFerias {
        padding: 3rem 0%;
    }
    .tituloFerias.tituloFerias {
        padding: 1rem 0 2rem;
        text-align: center;
        width: 100%;
    }
    .carrouselItem__title {
        padding: 0.5rem 0.5rem;
        line-height: 1.1;
        font-size: 1.8rem;
    }
    .carrouselBg:nth-of-type(1) {
        transform: rotate(4deg);
        top: -0.5rem;
    }
    .carrouselBg:nth-of-type(2) {
        transform: rotate(-5deg);
        top: 0.5rem;
    }
}

/*dispositivos xxs */
@media all and (min-width: 318px) and (max-width: 374px) {
    .ordenGeneralFerias.ordenGeneralFerias {
        padding: 4rem 0%;
    }
    .tituloFerias.tituloFerias {
        padding: 1rem 0;
        text-align: center;
        width: 100%;
    }
    .carrouselItem__title {
        padding: 1rem 1rem;
        line-height: 1.1;
        font-size: 1.8rem;
    }
    .carrouselBg:nth-of-type(1) {
        transform: rotate(5deg);
        top: -0.5rem;
    }
    .carrouselBg:nth-of-type(2) {
        transform: rotate(-6deg);
        top: 0.5rem;
    }
}

/*dispositivos xs */
@media all and (min-width: 374px) and (max-width: 390px) {
    .ordenGeneralFerias.ordenGeneralFerias {
        padding: 4rem 0%;
    }
    .tituloFerias.tituloFerias {
        padding: 1rem 0;
        text-align: center;
        width: 100%;
    }
    .carrouselItem__title {
        padding: 1rem 1rem;
        line-height: 1.1;
        font-size: 2rem;
    }
    .carrouselBg:nth-of-type(1) {
        transform: rotate(4deg);
        top: -0.5rem;
    }
    .carrouselBg:nth-of-type(2) {
        transform: rotate(-5deg);
        top: 0.5rem;
    }
}

/*dispositivos small */
@media all and (min-width: 390px) and (max-width: 700px) {
    .ordenGeneralFerias.ordenGeneralFerias {
        padding: 4rem 0%;
    }
    .tituloFerias.tituloFerias {
        padding: 1rem 0;
        text-align: center;
        width: 100%;
    }
    .carrouselItem__title {
        padding: 1rem 1rem;
        line-height: 1.1;
        font-size: 2rem;
    }

    .carrouselBg:nth-of-type(1) {
        transform: rotate(4deg);
        top: -0.5rem;
    }
    .carrouselBg:nth-of-type(2) {
        transform: rotate(-5deg);
        top: 0.5rem;
    }
}

/*dispositivos medium */
@media all and (min-width: 700px) and (max-width: 992px) {
    .ordenGeneralFerias.ordenGeneralFerias {
        padding: 4rem 0%;
    }
    .tituloFerias.tituloFerias {
        padding: 1rem 0;
        text-align: center;
        width: 100%;
    }
    .carrouselItem__title {
        padding: 0.3rem 0.3rem;
        line-height: 1.1;
        font-size: 1.5rem;
    }
}

/*dispositivos large */
@media all and (min-width: 992px) and (max-width: 1025px) {
    .carrouselItem__title {
        padding: 0.3rem 0.7rem;
        font-size: 1.5rem;
    }
}

/*dispositivos XL */
@media all and (min-width: 1025px) and (max-width: 1440px) {
    .carrouselItem__title {
        padding: 0.3rem 0.7rem;
        font-size: 1.6rem;
    }
}

/*dispositivos XXL */
@media all and (min-width: 1440px) and (max-width: 2560px) {
}

/*dispositivos 4k */
@media all and (min-width: 2560px) {
    .carrouselItem__title {
        font-size: 3rem;
    }
}
