main.nosotros {
    overflow: hidden;
}

.nosotros__videos {
    position: relative;
}

.nosotros__bg {
    position: absolute;
    z-index: -1;
    width: 3000px;
    aspect-ratio: 2 / 1;
    bottom: 50%;
    left: -15%;
    background-color: var(--greenMain);
    transform: rotate(8deg);
    box-shadow: 3px 3px 15px #111;
}

.section__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 75px);
    margin-top: 85px;
    margin-bottom: -3rem;
}

.nosotrosTitle {
    z-index: 2;
    font-size: var(--fs-titulos);
    text-align: center;
    font-weight: 400;
    color: #fff;
    margin-bottom: 3rem;
}

.nosotros__videoText {
    text-align: center;
    margin: 2rem auto;
    font-size: 1.2rem;
    width: 65%;
    white-space: pre-wrap;
}

.nosotros__videoContainer {
    display: flex;
    justify-content: center;
    margin: 0 auto 1rem;
    width: 55%;
}
.nosotros__Video.desktop {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: -2px 2px 25px #242424;
}
.nosotros__Video.mobile {
    display: none;
    width: 90%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: -2px 2px 25px #242424;
}

.nosotros__proceso {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 7rem;
}
.nosotros__procesoImg {
    width: 90%;
    margin: 0 auto;
}
.nosotros__procesoImg.mobile {
    display: none;
}

.textoNosotrosProceso {
    text-align: center;
    font-size: var(--fs-titulos);
    padding: 5%;
    color: var(--colorFooter);
    font-weight: 300;
}

/*Galaxy Fold  */

@media all and (max-width: 318px) {
    .section__container {
        justify-content: flex-start;
        padding-top: 2rem;
    }
    .nosotros__bg {
        bottom: 40%;
    }
    .nosotros__videoContainer {
        margin: 1rem auto 1rem;
        width: 90%;
        max-height: 550px;
    }
    .nosotros__Video.desktop {
        display: none;
    }
    .nosotros__Video.mobile {
        display: initial;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
        box-shadow: -2px 2px 25px #242424;
    }
    .nosotros__videoText {
        margin: 2rem auto;
    }
    .nosotros__procesoImg.desktop {
        display: none;
    }
    .nosotros__procesoImg.mobile {
        display: initial;
        width: 95%;
    }
    .textoNosotrosProceso {
        padding-bottom: 18%;
    }
}

/*dispositivos xxs */

@media all and (min-width: 318px) and (max-width: 374px) {
    .section__container {
        justify-content: flex-start;
        padding-top: 2rem;
    }
    .nosotros__bg {
        bottom: 40%;
    }
    .nosotros__videoContainer {
        margin: 1rem auto 1rem;
        width: 85%;
        max-height: 500px;
    }
    .nosotros__Video.desktop {
        display: none;
    }
    .nosotros__Video.mobile {
        display: initial;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
        box-shadow: -2px 2px 25px #242424;
    }
    .nosotros__videoText {
        margin: 2rem auto;
    }
    .nosotros__procesoImg.desktop {
        display: none;
    }
    .nosotros__procesoImg.mobile {
        display: initial;
        width: 95%;
    }
    .textoNosotrosProceso {
        padding-bottom: 18%;
    }
}

/*dispositivos xs */

@media all and (min-width: 374px) and (max-width: 390px) {
    .section__container {
        justify-content: flex-start;
        padding-top: 2rem;
    }
    .nosotros__bg {
        bottom: 40%;
    }
    .nosotros__videoContainer {
        margin: 1rem auto 1rem;
        width: 85%;
        max-height: 500px;
    }
    .nosotros__Video.desktop {
        display: none;
    }
    .nosotros__Video.mobile {
        display: initial;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
        box-shadow: -2px 2px 25px #242424;
    }
    .nosotros__videoText {
        margin: 2rem auto;
    }
    .nosotros__procesoImg.desktop {
        display: none;
    }
    .nosotros__procesoImg.mobile {
        display: initial;
        width: 90%;
    }
    .textoNosotrosProceso {
        padding-bottom: 18%;
    }
}
/*dispositivos xs */

@media all and (min-width: 390px) and (max-width: 540px) {
    .section__container {
        justify-content: flex-start;
        padding-top: 2rem;
    }
    .nosotros__bg {
        bottom: 30%;
    }
    .nosotros__videoContainer {
        margin: 1rem auto 1rem;
        width: 80%;
        max-height: 550px;
    }
    .nosotros__Video.desktop {
        display: none;
    }
    .nosotros__Video.mobile {
        display: initial;
        width: 90%;
        object-fit: cover;
        border-radius: 10px;
        box-shadow: -2px 2px 25px #242424;
    }
    .nosotros__videoText {
        margin: 2rem auto;
    }
    .nosotros__procesoImg.desktop {
        display: none;
    }
    .nosotros__procesoImg.mobile {
        display: initial;
        width: 70%;
    }
    .textoNosotrosProceso {
        padding-bottom: 15%;
    }
}

/*dispositivos small */

@media all and (min-width: 540px) and (max-width: 768px) {
    .nosotros__bg {
        bottom: 35%;
    }

    .nosotros__videoContainer {
        margin: 1rem auto 0;
        width: 90%;
    }

    .nosotros__procesoImg.desktop {
        display: none;
    }
    .nosotros__procesoImg.mobile {
        display: initial;
        width: 50%;
    }
}

/*dispositivos medium */

@media all and (min-width: 768px) and (max-width: 992px) {
    .section__container {
        justify-content: center;
    }
    .nosotros__videoContainer {
        margin: 1rem auto 1rem;
        width: 80%;
    }
    .nosotros__videoText {
        font-size: 1.2rem;
        width: 85%;
    }
    .textoNosotrosProceso {
        padding-bottom: 12%;
    }
}

/*dispositivos large */

@media all and (min-width: 992px) and (max-width: 1025px) {
    .nosotros__videoContainer {
        margin: 1rem auto 1rem;
        width: 80%;
    }
    .nosotros__videoText {
        font-size: 1.2rem;
        width: 80%;
    }
}

/*dispositivos XL */

@media all and (min-width: 1025px) and (max-width: 1440px) {
    .nosotros__videoContainer {
        margin: 0.5rem auto 1rem;
        width: 65%;
    }
    .nosotros__videoText {
        font-size: 1.2rem;
    }
}

/*dispositivos XXL */

@media all and (min-width: 1440px) and (max-width: 2560px) {
}

/*dispositivos 4k */

@media all and (min-width: 2560px) and (max-width: 3000px) {
    .nosotros__bg {
        width: 4000px;
        aspect-ratio: 2 / 1;
        bottom: 50%;
        left: -10%;
    }
}
/*> a dispositivos 4k */

@media all and (min-width: 3000px) {
    .nosotros__bg {
        width: 8500px;
        left: -5%;
    }
}
