.modalContainer {
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.712);
    padding: 0 30%;
    box-sizing: border-box;
    position: fixed;
    z-index: 200;
    left: 0;
    top: 0%;
    width: 100vw;
    height: 100vh;
}
.modalContainer.active {
    display: flex;
}
.modalItem__container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
}
.modalItem__img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
}

.modalItem__title {
    position: absolute;
    z-index: 201;
    text-align: start;
    box-sizing: border-box;
    font-weight: 300;
    bottom: 0%;
    color: white;
    background-color: #252525;
    padding: 1.5rem 1rem;
    font-size: 1.5rem;
    margin: 0;
    width: 100%;
}
/* con el max-widht ajustas el tamaño de la imagen del modal */
.modalItem__container {
    width: 100%;
    max-width: 600px;
}

.modalCloseOverlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
}
.modalCloseOverlayText {
    font-size: calc(var(--fs-titulos) - 0.5rem);
    margin-bottom: 2rem;
    text-align: center;
    color: #fff;
}
.modalCloseBtn {
    display: grid;
    place-items: center;
    position: absolute;
    z-index: 200;
    height: 2.5rem;
    aspect-ratio: 1 / 1;
    padding: 0.5rem;
    top: 10%;
    cursor: pointer;
    background-color: #ffffff95;
    border-radius: 50%;
    right: 25%;
    transition: 0.3s;
}
.modalCloseBtn:hover {
    background-color: #fff;
}

.closeBtnBar {
    background-color: #000000;
    height: 1.5px;
    width: 100%;
    border-radius: 3px;
}
.closeBtnBar:nth-of-type(1) {
    transform: rotate(45deg) translateY(0.25rem) translateX(0.25rem);
}
.closeBtnBar:nth-of-type(2) {
    transform: rotate(-45deg) translateY(-0.3rem) translateX(0.25rem);
}

/*Galaxy Fold */

@media all and (max-width: 318px) {
    .modalContainer {
        padding: 0 1%;
    }
    .modalItem__container {
        width: 100%;
        max-width: 600px;
    }
    .modalCloseBtn {
        top: 22%;
        right: 5%;
    }
    .modalItem__title {
        padding: 1rem 1rem;
        font-size: 1.2rem;
    }
}

/*dispositivos xxs */

@media all and (min-width: 318px) and (max-width: 374px) {
    .modalContainer {
        padding: 0 1%;
    }
    .modalItem__container {
        width: 100%;
        max-width: 600px;
    }
    .modalCloseBtn {
        top: 18%;
        right: 5%;
    }
    .modalItem__title {
        padding: 1rem 1rem;
        font-size: 1.2rem;
    }
}

/*dispositivos xs */

@media all and (min-width: 374px) and (max-width: 390px) {
    .modalContainer {
        padding: 0 2%;
    }
    .modalItem__container {
        width: 100%;
        max-width: 600px;
    }
    .modalCloseBtn {
        top: 18%;
        right: 5%;
    }
    .modalItem__title {
        padding: 1rem 1rem;
        font-size: 1.2rem;
    }
}

/*dispositivos small */

@media all and (min-width: 390px) and (max-width: 540px) {
    .modalContainer {
        padding: 0 2%;
    }
    .modalItem__container {
        width: 100%;
        max-width: 600px;
    }
    .modalCloseBtn {
        top: 18%;
        right: 5%;
    }
    .modalItem__title {
        padding: 1rem 1rem;
        font-size: 1.2rem;
    }
}

/*dispositivos small-medium */

@media all and (min-width: 540px) and (max-width: 768px) {
    .modalContainer {
        padding: 0 5%;
    }
    .modalItem__container {
        width: 100%;
        max-width: 600px;
    }
    .modalCloseBtn {
        top: 10%;
        right: 5%;
    }
}

/*dispositivos medium */

@media all and (min-width: 768px) and (max-width: 992px) {
    .modalContainer {
        padding: 0 10%;
    }
    .modalCloseBtn {
        top: 10%;
        right: 10%;
    }
}

/*dispositivos large */

@media all and (min-width: 992px) and (max-width: 1025px) {
    .modalContainer {
        padding: 0 18%;
    }
    .modalCloseBtn {
        top: 10%;
        right: 18%;
    }
}

/*dispositivos XL */

@media all and (min-width: 1025px) and (max-width: 1440px) {
    .modalContainer {
        padding: 0 25%;
    }
    .modalCloseBtn {
        top: 10%;
        right: 18%;
    }
}

/*dispositivos XXL */

@media all and (min-width: 1440px) and (max-width: 2560px) {
}

/*dispositivos 4k */

@media all and (min-width: 2560px) and (max-width: 3000px) {
}
