.ordenCalendario {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 3%;
}
.seccionTitulo {
    background-color: var(--greenMain);
    width: 100%;
    padding: 75px 0 0px;
    margin-bottom: 3rem;
}

.tituloCalendario {
    font-size: var(--fs-titulos);
    font-weight: 400;
    color: white;
    width: 100%;
    padding: 2% 0%;
    text-align: center;
}

.calendarioCas {
    width: 55%;
}

/* dispositivos small  */

@media all and (max-width: 767px) {
    .tituloCalendario {
        padding: 2% 0%;
        text-align: center;
    }

    .calendarioCas {
        width: 95%;
    }
}

/* dispositivos medium */

@media all and (min-width: 768px) and (max-width: 991px) {
    .tituloCalendario {
        padding: 2% 0%;
        text-align: center;
    }

    .calendarioCas {
        width: 85%;
    }
}

/* dispositivos large */

@media all and (min-width: 992px) and (max-width: 1025px) {
    .tituloCalendario {
        padding: 2% 0%;
        text-align: center;
    }

    .calendarioCas {
        width: 75%;
    }
}

/* dispositivos XL */

@media all and (min-width: 1026px) and (max-width: 1440px) {
    .tituloCalendario {
        padding: 2% 0%;
        text-align: center;
    }

    .calendarioCas {
        width: 75%;
    }
}
