.loadScreen {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #eee;
}
.loadScreen__img {
    max-width: 200px;
}
.loadScreen__title {
    font-size: calc(var(--fs-titulos) * 0.7);
    font-weight: 400;
}
